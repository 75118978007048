import { axios, API_ROUTES } from "@/config/index.js";

export default {
  async search(identification) {
    let result = await axios.get(`${API_ROUTES.client.get}/${identification}`);

    return result;
  },

  async update(id, data) {
    let result = await axios.put(`${API_ROUTES.client.update}/${id}`, data);

    return result;
  }

}